@import "minima";

$spotify-green: #1BB954;

.fa-spotify {
  color: $spotify-green;
}

.spotify {
  background-image: url(/assets/images/spotify_logo.png);
  background-size: 1.1rem;
  width: 1.1rem;
  height: 1.1rem;
  vertical-align: text-top;
  display: inline-block;
}

.circle {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.flashing {
  animation-name: flashing;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes flashing {
  0% { opacity: 1; }
  20% { opacity: .25; }
  40% { opacity: 1; }
  100% { opacity: 1; }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(158, 235, 162, 0.6);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 1.5rem;    /* Footer height */
}

#site-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1.5rem;            /* Footer height */
}

/* Flexible image containers (use 25% for four, and 50% for two, etc) */
.col-sm {
  float: left;
  width: 50%;
  padding: 5px;
}

/* Clear floats after image containers */
.row{
  display:flex;
  align-items:center
}

.text-center {
  text-align: center;
}

.MathJax_Display, .MJXc-display, .MathJax_SVG_Display {
    overflow-x: auto;
    overflow-y: hidden;
}

d-contents{align-self:start;grid-column:1 / 4;grid-row:auto / span 4;justify-self:end;margin-top:0;padding-left:2em;padding-right:3em;border-right:1px solid var(--global-divider-color);width:calc(max(70%,300px));margin-right:0;margin-top:0;display:grid;grid-template-columns:minmax(8px,1fr) [toc] auto minmax(8px,1fr) [toc-line] 1px minmax(32px,2fr)}d-article d-contents nav{grid-column:toc}d-article d-contents nav a{border-bottom:none!important}d-article d-contents nav a:hover{border-bottom:1px solid var(--global-text-color)!important}d-article d-contents nav h3{margin-top:0;margin-bottom:1em}d-article d-contents nav div{display:block;outline:0;margin-bottom:.8em;color:rgba(0,0,0,0.8);font-weight:bold}d-article d-contents nav ul{padding-left:1em;margin-top:0;margin-bottom:6px;list-style-type:none}d-article d-contents nav ul li{margin-bottom:.25em}d-article d-contents .figcaption{line-height:1.4em}d-article d-contents toc-line{border-right:1px solid var(--global-divider-color);grid-column:toc-line}d-article d-footnote{scroll-margin-top:66px}d-appendix{border-top-color:var(--global-divider-color)!important;color:var(--global-distill-app-color)!important}d-appendix h3,d-appendix li,d-appendix span{color:var(--global-distill-app-color)!important}d-appendix a,d-appendix a.footnote-backlink{color:var(--global-distill-app-color)!important}d-appendix a:hover,d-appendix a.footnote-backlink:hover{color:var(--global-hover-color)!important}@media(max-width:1024px){d-article d-contents{display:block;grid-column-start:2;grid-column-end:-2;padding-bottom:.5em;margin-bottom:1em;padding-top:.5em;width:100%;border:1px solid var(--global-divider-color)}d-article d-contents nav{grid-column:none}}

d-article {
  d-contents {
    align-self: start;
    grid-column: 1 / 4;
    grid-row: auto / span 4;
    justify-self: end;
    margin-top: 0em;
    padding-left: 2em;
    padding-right: 3em;
    border-right: 1px solid var(--global-divider-color);
    width: calc(max(70%, 300px));
    margin-right: 0px;
    margin-top:  0em;
    display: grid;
    grid-template-columns:
      minmax(8px, 1fr) [toc] auto
      minmax(8px, 1fr) [toc-line] 1px
      minmax(32px, 2fr);

    nav {
      grid-column: toc;
      a {
        border-bottom: none !important;
        &:hover {
          border-bottom: 1px solid var(--global-text-color) !important;
        }
      }
      h3 {
        margin-top: 0;
        margin-bottom: 1em;
      }
      div {
        display: block;
        outline: none;
        margin-bottom: 0.8em;
        color: rgba(0, 0, 0, 0.8);
        font-weight: bold;
      }
      ul {
        padding-left: 1em;
        margin-top: 0;
        margin-bottom: 6px;
        list-style-type: none;
        li {
          margin-bottom: 0.25em;
        }
      }
    }
    .figcaption {
      line-height: 1.4em;
    }
    toc-line {
      border-right: 1px solid var(--global-divider-color);
      grid-column: toc-line;
    }
  }

  d-footnote {
    scroll-margin-top: 66px;
  }
}

d-appendix {
  border-top-color: var(--global-divider-color) !important;
  color: var(--global-distill-app-color) !important;
  h3, li, span {
    color: var(--global-distill-app-color) !important;
  }
  a, a.footnote-backlink {
    color: var(--global-distill-app-color) !important;
    &:hover {
      color: var(--global-hover-color) !important;
    }
  }
}

@media (max-width: 1024px) {
  d-article {
    d-contents {
      display: block;
      grid-column-start: 2;
      grid-column-end: -2;
      padding-bottom: 0.5em;
      margin-bottom: 1em;
      padding-top: 0.5em;
      width: 80%;
      border: 1px solid var(--global-divider-color);
      nav {
        grid-column: none;
      }
    }
  }
}
